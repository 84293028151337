import React from "react";

const Card = ({ options }) => {
  return (
    <div className="bg-white border rounded-10 pt-9 pl-10 pr-10 pb-8 mr-5 mr-lg-9">
      <span className="bg-blue-3-op1 circle-48 font-size-8 mb-8">
        <i className={`fa ${options.icon} text-blue-3`}></i>
      </span>
      <div className="">
        <h4 className="font-size-6 mb-0 text-dark-cloud">{options.userlink}</h4>
        <p className="font-size-5 line-height-28 text-dark-cloud mr-xl-5">
          {options.content}
        </p>
      </div>
    </div>
  );
};

export default Card;
