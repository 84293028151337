import React, { useRef } from "react";
import Slider from "react-slick";

import TestimonialCard1 from "../../components/TestimonialCard1";

const items = [
  {
    icon: "fa-mobile",
    content:
      "Con el fichaje geolocalizado de nuestra app, tus empleados podrán fichar desde donde tu quieras. Además siempre estarán conectados con la empresa y podrán gestionar todas sus necesidades.",
    userlink: "Aplicación móvil"
  },
  {
    icon: "fa-calendar-alt",
    content:
      "Configura y asigna los horarios de trabajo a tus empleados en tiempo real. Recibirán notificaciones sobre los nuevos cambios para que no se pierdan nada.",
    userlink: "Planificación"
  },
  {
    icon: "fa-retweet",
    content:
      "Gestión de permutas de turnos, servicios o vacaciones entre empleados... con o sin la intervención de un responsable.",
    userlink: "Permutas"
  },
  {
    icon: "fa-map-marker",
    content:
      "Con los centros de trabajo puedes indicar desde donde pueden fichar tus empleados… ¡o dales libertad, depende de ti!",
    userlink: "Centros de trabajo"
  },
  {
    icon: "fa-sign-out-alt",
    content:
      "Configura tipos de ausencias y decide si computa en las horas o si los empleados pueden solicitarlas. Añade los descansos y su duración máxima.",
    userlink: "Absentismos"
  },
  {
    icon: "fa-clock",
    content:
      "Gestión de tipos de horas extras que se pueden realizar, ya sean solicitadas por un empleado o generadas por un responsable tras una jornada excedida.",
    userlink: "Horas extras"
  },
  {
    icon: "fa-umbrella-beach",
    content:
      "Los empleados pueden solicitar sus vacaciones pendientes del año. Los responsables pueden gestionar las vacaciones de un equipo con el calendario de vacaciones.",
    userlink: "Vacaciones"
  },
  {
    icon: "fa-chart-line",
    content:
      "Panel de control de KPIs para consultar datos como la tasa de absentismo, el índice de accidentalidad o la media de los fichajes no realizadose entre otros... la evolución de tu empresa en una misma pantalla.",
    userlink: "KPIs"
  },
  {
    icon: "fa-bell",
    content:
      "Para que toda la empresa esté al día, utilizamos las notificaciones push tanto para la versión web como para la app nativa para notificar cualquier cambio en tiempo real.",
    userlink: "Notificaciones"
  }
];

const Testimonial = ({ className, ...rest }) => {
  const elSlider = useRef();

  const slickSettings = {
    autoplay: false,
    slidesToShow: 4,
    arrows: false,
    infinite: true,
    dots: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 468,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <>
      <div className={className} {...rest}>
        <div className="container">
          {/* <!-- Section Title --> */}
          <div className="mb-8 mb-lg-5">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-9">
                <div
                  className="mb-8 mb-lg-16 mb-md-0 text-center text-md-left"
                  data-aos="fade-right"
                  data-aos-delay="500"
                >
                  <span className="bg-dodger-blue-2-op1 circle-84 font-size-9 mb-5 mb-lg-11 mx-auto mx-md-0">
                    <i className="fa fa-star text-dodger-blue-2"></i>
                  </span>
                  <h2 className="font-size-11 mb-0">
                    Lo que nos hace la herramienta de control horario perfecta
                  </h2>
                </div>
              </div>
              <div
                className="col-lg-5 col-md-3"
                data-aos="fade-left"
                data-aos-delay="500"
              >
                <div className="testimonial-one-button text-center text-md-right mt-3 mt-md-18">
                  <button
                    type="button"
                    className="slick-prev"
                    onClick={() => {
                      elSlider.current.slickPrev();
                    }}
                  >
                    <i className="icon icon-minimal-left"></i>
                  </button>
                  <button
                    type="button"
                    className="slick-next"
                    onClick={() => {
                      elSlider.current.slickNext();
                    }}
                  >
                    <i className="icon icon-minimal-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- End Section Title --> */}
          {/* <!-- category slider --> */}
          <div className="row">
            <div className="col-12">
              <div className="testimonial-one">
                <Slider
                  ref={elSlider}
                  {...slickSettings}
                  css={`
                    .slick-dots {
                      display: flex !important;
                    }
                  `}
                >
                  {items.map((item, index) => (
                    <div className="single-category focus-reset" key={index}>
                      <TestimonialCard1 options={item} />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
          {/* <!-- End category slider --> */}
        </div>
      </div>
    </>
  );
};

export default Testimonial;
