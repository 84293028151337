import React from "react";

import PageWrapper from "../../components/PageWrapper";
import Hero from "../../sections/time/Hero";
import Counter from "../../sections/time/Counter";
import Progress from "../../sections/time/Progress";
import Content1 from "../../sections/time/Content1";
import Content2 from "../../sections/time/Content2";
import Content4 from "../../sections/time/Content4";
import Testimonial from "../../sections/time/Testimonial";

const Time = () => {
  return (
    <>
      <PageWrapper>
        <Hero className="pt-11 pt-lg-34 pb-8 pb-lg-33" />
        <Counter className="pt-lg-5 pb-8 pb-lg-27" />
        <Progress className="pb-lg-21 border-top border-default-color-1" />
        <Content1 className="pb-10 pb-sm-5 pb-lg-14" />
        <Content2 className="pb-0 pb-lg-31" />
        <Testimonial className="pt-lg-19 pb-12 pb-lg-17" />
        <Content4 className="pt-13 pt-lg-28 pb-13 pb-lg-26" />
      </PageWrapper>
    </>
  );
};
export default Time;
